<template>
  <div class="data-box">
    <div class="data-box-body">
      <div class="body-title">
        <div class="title-active">{{ activeInfo.activityName }}</div>
        <div class="title-company">{{ activeInfo.companyName }}</div>
      </div>
      <div class="body-info">
        <div class="info-person">
          <van-image class="info-person-image" :src="require('../../assets/image/org_head.png')"/>
          <div class="info-person-name">{{ activeInfo.loginName }}</div>
        </div>
        <div class="info-work">
          <div class="info-work-item" v-for="(item,index) in orgWorkList" :key="index" v-show="item.isShow">
            <i :class="'iconfont ' + item.icon"></i>
            <span>{{item.text}}</span>
          </div>
        </div>
      </div>
      <div class="body-active">
        <div class="active-item">
          <van-image class="active-item-image" :src="require('../../assets/image/org_head.png')"/>
          <div class="active-item-info">
            <div class="item-info-name">{{ assistantInfo.name }}</div>
            <div class="item-info-job">{{ assistantInfo.title }}</div>
          </div>
          <div class="active-item-icon">
            <a :href="'tel:' + assistantInfo.phone" class="iconfont icon-dianhua1" v-show="assistantInfo.phone"></a>
          </div>
        </div>
        <div class="active-div">
          <div class="active-div-item" v-for="(item,index) in itemList" :key="index" @click="toActiveArrange(item)">
            <div class="item-left">
              <div class="item-left-text">{{item.text}}</div>
              <div class="item-left-num">{{item.num + ' 人'}}</div>
            </div>
            <div class="item-right">
              <van-image class="item-right-image" :src="item.image"/>
            </div>
          </div>
        </div>
        <div class="active-item" @click="showPersonnelList">
          <div class="active-item-text">
            查看工作人员名单
          </div>
          <div class="active-item-icon">
            <i class="iconfont icon-order-join info-icon2"></i>
          </div>
        </div>
        <div class="active-item">
          <div class="active-item-text">
            接收新签到人员通知
          </div>
          <van-switch class="active-item-switch" v-model="isOpen" active-color="#3476FF" inactive-color="#F2F5FC" :active-value="1" :inactive-value="0" @change="receiveNotice"/>
        </div>
      </div>
    </div>
    <van-popup v-model="showPersonPopup" round position="bottom" :style="{ height: '70%' }">
      <div class="popup-body">
        <div class="check-title">
          工作人员名单
        </div>
        <div class="check-info" v-for="(item,index) in headInfoArray" :key="index" v-show="item.relateType === 7 && item.chargeName">
          <div class="check-info-left">{{ item.objName }}</div>
          <div class="check-info-right">
            <span>{{ item.chargeName ? item.chargeName : "无" }}</span>
            <a :href="'tel:' + item.phoneSite" class="iconfont icon-dianhua1" v-show="item.phoneSite"></a>
          </div>
        </div>
        <div class="check-line"></div>
        <div class="check-title">
          其他负责人
        </div>
        <div class="check-info" v-for="(item,index) in headInfoArray" :key="index" v-show="item.relateType !== 7 && item.chargeName">
          <div class="check-info-left">{{ item.objName }}</div>
          <div class="check-info-right">
            <span>{{ item.chargeName ? item.chargeName : "无" }}</span>
            <a :href="'tel:' + item.phoneSite" class="iconfont icon-dianhua1" v-show="item.phoneSite"></a>
          </div>
        </div>
      </div>
    </van-popup>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {
  tissueFirstPage,
  addSetActivityOrActivityObject,
  listActivityOrganizers
} from "@/api/index";

export default {
  name: "orgIndex",
  data() {
    return {
      activeInfo: {},
      orgWorkList: [
        { icon: "icon-icon-", text: "人员检查", role: "现场总指挥,人员对接责任人", isShow: false},
        { icon: "icon-neibuyongche", text: "车辆对接", role: "现场总指挥,车辆对接责任人", isShow: false},
        { icon: "icon-qiandao2", text: "负责签到", role: "现场总指挥,人员对接责任人,车辆对接责任人", isShow: false},
        { icon: "icon-qiandao2", text: "负责签退", role: "现场总指挥,人员对接责任人,车辆对接责任人", isShow: false},
        { icon: "icon-wuzijihua", text: "物资发放", role: "现场总指挥,物资发放责任人", isShow: false},
        { icon: "icon-daochat", text: "用餐安排", role: "现场总指挥,用餐安排责任人", isShow: false},
        { icon: "icon-jiudian", text: "住宿安排", role: "现场总指挥,住宿安排责任人", isShow: false}
      ],
      itemList: [
        { image: require("../../assets/image/index_check.svg"), text: "对接工作" , num: 0, router: "orgCheck", role: "现场总指挥,人员对接责任人,车辆对接责任人", isHad: false },
        { image: require("../../assets/image/index_live.svg"), text: "住宿管理" , num: 0, router: "orgLive", role: "现场总指挥,住宿安排责任人", isHad: false },
        { image: require("../../assets/image/index_meals.svg"), text: "用餐管理" , num: 0, router: "orgMeals", role: "现场总指挥,用餐安排责任人", isHad: false },
        { image: require("../../assets/image/index_material.svg"), text: "物资发放" , num: 0, router: "orgMaterial", role: "现场总指挥,物资发放责任人", isHad: false },
        { image: require("../../assets/image/index_consump.svg"), text: "车辆能耗" , num: 0, router: "orgConsumption", role: "现场总指挥,车辆服务责任人", isHad: false },
        { image: require("../../assets/image/index_clear.svg"), text: "车辆清洁" , num: 0, router: "orgClear", role: "现场总指挥,车辆服务责任人", isHad: false }
      ],
      isOpen: 0, //是否开启人员签到通知
      showPersonPopup: false,
      assistantInfo: { relateType: 8, title: "我的助理", name: "", phone: "" },
      headInfoArray: []
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let params = {
        ayId: that.$store.getters.getAyPerson.id,
        serialNo: that.$store.getters.getSerialNo
      };
      tissueFirstPage(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.activeInfo = res.data;
          that.itemList.forEach((item,index) => {
            that.itemList[index].num = that.activeInfo.adminObjectVOS[index].num;
            let isHad = false;
            item.role.split(",").forEach(childItem => {
              if(that.activeInfo.loginRole.split(",").includes(childItem)){
                isHad = true;
              }
            })
            item.isHad = isHad;
          })
          that.activeInfo.loginRole.split(",").forEach(item => {
            that.orgWorkList.forEach(childItem => {
              if(childItem.role.split(",").includes(item)){
                childItem.isShow = true;
              }
            })
          })
          that.activeInfo.ass.forEach(item => {
            if(item.relateType === 8){
              that.assistantInfo.name = item.chargeName;
              that.assistantInfo.phone = item.chargePhone;
            }
          })
          that.getActivityOrganizers();
        })
      },err => {})
    },
    //获取工作人员名单
    getActivityOrganizers(){
      let that = this;
      let params = {
        activityId: that.$store.getters.getAyPerson.activityId
      };
      listActivityOrganizers(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.headInfoArray = res.data;
          that.$store.commit("setJobList",res.data)
        })
      })
    },
    //前往安排活动 0：对接工作，1：物资发放，2：住宿管理，3：车辆清洁，4：用餐管理，5：车辆能耗
    toActiveArrange(item){
      let that = this;
      if(Object.prototype.hasOwnProperty.call(item, "router")){
        that.$router.push(item.router);
      }
      /*if(item.isHad){

      }else{
        that.$toast("您不是该模块的负责人哦！");
      }*/
    },
    showPersonnelList(){
      let that = this;
      that.showPersonPopup = true;
    },
    //接收签到人员通知
    receiveNotice(){
      let that = this;
      let params = {
        type: 1,
        id: that.$store.getters.getAyPerson.id,
        isNote: that.isOpen
      }
      addSetActivityOrActivityObject(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.$toast(that.isOpen ? "已开启" : "已关闭");
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.data-box{
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  .data-box-body{
    width: 100%;
    height: 100%;
    background-image:url('../../assets/image/org_index_bg.png');
    background-repeat:no-repeat;
    background-size: 100% 234px;
    background-position:center top;
    .body-title{
      width: 90%;
      margin: 0px auto;
      padding: 20px 0px;
      text-align: center;
      .title-active{
        color: #FFFFFF;
        font-size: 20px;
      }
      .title-company{
        margin-top: 10px;
        color: #FFFFFF;
        font-size: 16px;
      }
    }
    .body-info{
      width: 90%;
      margin: 10px auto;
      padding: 10px 0px;
      background-color: #FFFFFF;
      border-radius: 20px;
      .info-person{
        width: 100%;
        text-align: center;
        .info-person-image{
          width: 54px;
          height: 54px;
        }
        .info-person-name{
          color: #3E4576;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .info-work{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        .info-work-item{
          width: 33.3%;
          text-align: center;
          i{
            color: #3476FF;
            font-size: 12px;
          }
          span{
            color: #8B8FAD;
            font-size: 12px;
            margin-left: 10px;
          }
        }
      }
    }
    .body-active{
      width: 90%;
      margin: 0px auto;
      .active-item{
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #FFFFFF;
        margin-top: 10px;
        padding: 6px;
        border-radius: 20px;
        .active-item-image{
          width: 50px;
          height: 50px;
        }
        .active-item-info{
          width: 60%;
          padding-left: 10px;
          .item-info-name{
            color: #3E4576;
            font-size: 16px;
          }
          .item-info-job{
            margin-top: 6px;
            color: #8B8FAD;
            font-size: 12px;
          }
        }
        .active-item-text{
          width: 74%;
          margin-left: 10px;
          color: #3E4576;
          font-size: 16px;
        }
        .active-item-switch{
          margin: 4px 0px;
        }
        .active-item-icon{
          width: 20%;
          text-align: right;
          a{
            margin-left: 16px;
            font-size: 20px;
            color: #3476FF;
          }
          .info-icon1{
            color: #3476FF;
            font-size: 40px;
          }
          .info-icon2{
            color: cornflowerblue;
            font-size: 32px;
          }
        }
      }
      .active-div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        .active-div-item{
          width: 48%;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 10px;
          background-color: #FFFFFF;
          border-radius: 20px;
          .item-left{
            width: 50%;
            padding-left: 10%;
            .item-left-text{
              color: #3E4576;
              font-size: 15px;
            }
            .item-left-num{
              margin-top: 6px;
              color: #FF6218;
              font-size: 12px;
            }
          }
          .item-right{
            width: 40%;
            padding-top: 18px;
            .item-right-image{
              width: 48px;
              height: 48px;
            }
          }
        }
      }
    }
  }
  .popup-body{
    width: 100%;
    padding: 20px 0px;
    .check-title{
      width: 100%;
      text-align: center;
      color: #3E4576;
      font-size: 16px;
    }
    .check-info{
      width: 80%;
      margin: 10px auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .check-info-left{
        width: 40%;
        text-align: left;
        color: #3E4576;
        font-size: 14px;
      }
      .check-info-right{
        width: 40%;
        text-align: right;
        span{
          color: #3E4576;
          font-size: 14px;
        }
        a{
          margin-left: 16px;
          font-size: 20px;
          color: #3476FF;
        }
      }
    }
    .check-line{
      width: 80%;
      margin: 20px auto;
      height: 1px;
      border-top: 1px solid gainsboro;
    }
  }
}
</style>